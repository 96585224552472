<template>
  <div class="layout_dialog">
    <div v-show="$store.getters.dialogShow" class="contact_dialog">
      <div class="contact_dialog_bg">
        <div class="footer_center">
          <div class="footer_left">
            <div class="footer_title">共同协作</div>
            <div class="footer_subhead">探索出你的数字化解决方案</div>
            <div class="footer_icon-box">
              <div class="footer_icon_image">
                <img src="../assets/icons/footer_icon_1.png" alt="" />
                <div class="footer_icon_describe">联系我们</div>
              </div>
              <div class="footer_icon_image">
                <img src="../assets/icons/footer_icon_2.png" alt="" />
                <div class="footer_icon_describe">定制服务</div>
              </div>
              <div class="footer_icon_image">
                <img src="../assets/icons/footer_icon_3.png" alt="" />
                <div class="footer_icon_describe">规划方案</div>
              </div>
              <div class="footer_icon_image">
                <img src="../assets/icons/footer_icon_4.png" alt="" />
                <div class="footer_icon_describe">预约演示</div>
              </div>
            </div>
          </div>
          <el-form
            :model="form"
            :rules="rules"
            :show-message="false"
            :status-icon="true"
            ref="FormRef"
            class="footer_right"
          >
            <div class="footer_title">了解定制方案</div>
            <el-form-item prop="company">
              <input
                class="form_input"
                type="text"
                v-model="form.company"
                placeholder="请输入公司名称"
              />
            </el-form-item>
            <el-form-item prop="name">
              <input
                class="form_input"
                type="text"
                v-model="form.name"
                placeholder="请输入联系人"
              />
            </el-form-item>
            <el-form-item prop="phone">
              <input
                class="form_input"
                type="text"
                v-model="form.phone"
                placeholder="请输入联系方式"
              />
            </el-form-item>
            <el-form-item prop="code">
              <div style="display: flex; gap: 14px">
                <input
                  class="form_input"
                  type="text"
                  v-model="form.code"
                  placeholder="请输入验证码"
                />
                <div class="send_code_btn pointer" @click="sendCode">
                  {{ codeMsg }}
                </div>
              </div>
            </el-form-item>
            <div class="clause">
              <input type="checkbox" v-model="checked" name="check1" />
              <div>
                我同意通过电话、短信或其他电子方式接受兴智数产品和服务信息
              </div>
            </div>
            <div class="form_btn pointer" @click="submit">预约接入</div>
          </el-form>
        </div>
      </div>
    </div>
    <div
      v-show="$store.getters.dialogShow"
      @click="closeDialog"
      class="contact_lazy pointer"
    ></div>
  </div>
</template>

<script>
import { messageApi, guestbook } from "@/api/system";
export default {
  name: "ContactDialog",
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("联系方式不能为空"));
      } else {
        const reg = /^1[3-9][0-9]\d{8}$/;
        // 这里定义的reg变量没有加引号，所以是一种特殊的数据类型，即正则，可以直接用test()方法
        if (reg.test(value)) {
          callback();
        } else {
          // 具体错误信息没有提示
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      form: {
        company: "",
        name: "",
        phone: "",
        code: "",
      },
      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        phone: [
          { min: 11, message: "手机号不足11位", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      codeMsg: "获取验证码",
      checked: false,
    };
  },
  methods: {
    closeDialog() {
      this.form = {};
      this.checked = false;
      this.codeMsg = "获取验证码";
      this.$store.commit("settings/DIALOGSHOW_CHANGE");
    },
    submit() {
      this.$refs.FormRef.validate(async (valid, object) => {
        if (valid) {
          if (!this.checked) return this.$message.error("请先同意下面协议");
          let res = await guestbook(this.form);
          if (res.status == 0) {
            this.$message.success("预约成功！");
            this.from = {};
          } else {
            this.$message.error(res.error);
          }
        } else {
          for (let key in object) {
            this.$message.error(object[key][0].message);
            return false;
          }
        }
      });
    },
    async sendCode() {
      if (this.codeMsg === "验证码已发送") return;
      this.$refs.FormRef.validateField("phone", async (error) => {
        console.log(error);
        if (!!error) {
          this.$message.error(error);
        } else {
          let res = await messageApi({ phone: this.form.phone });
          if (res.status == 0) {
            this.codeMsg = "验证码已发送";
            this.$message.success("发送成功！");
          }
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.layout_dialog {
  ::v-deep .el-form-item {
    margin: 0;
  }
  .contact_dialog {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 12;
    width: 1100px;
    height: 686px;
    background-color: white;
    transform: translateX(-50%) translateY(-50%);

    .contact_dialog_bg {
      background-image: url("../assets/image/contact_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 11;
      .footer_center {
        //   width: 1240px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        //   transform: scale(0.7);
        .footer_left {
          margin-top: 140px;
          margin-left: 120px;
          .footer_title {
            font-size: 48px;
            font-weight: 600;
            color: #3562ff;
          }

          .footer_subhead {
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            font-size: 24px;
            margin-top: 13pxpx;
          }

          .footer_icon-box {
            display: flex;
            gap: 66px;
            width: 220px;
            flex-wrap: wrap;
            margin-top: 78px;
            .footer_icon_image {
              width: 72px;
              height: 72px;
              flex: 0 0 auto;
              img {
                height: 100%;
              }

              .footer_icon_describe {
                font-weight: 400;
                color: rgba(0, 0, 0, 0.7);
                text-align: center;
              }
              // height: 72px;
            }
            .footer_icon_image:nth-child(3) {
              img {
                margin-left: 10px;
              }
            }
          }
        }
        .footer_right {
          width: 584px;
          height: 560px;
          background: #ffffff;
          box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          border: 2px solid #3562ff;
          padding: 55px 92px 0 92px;
          transform: scale(0.8);
          margin-top: 53px;
          margin-right: 20px;
          .footer_title {
            font-size: 48px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
            margin-bottom: 23px;
          }
          .form_input {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            border: 1px solid #9f9f9f;
            margin-bottom: 12px;
            font-size: 18px;
            padding: 14px 25px;
          }
          .send_code_btn {
            width: 136px;
            height: 48px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            border: 1px solid #3562ff;
            color: #3562ff;
            line-height: 48px;
            text-align: center;
            font-size: 14px;
          }
          .clause {
            display: flex;
            align-items: flex-start;
            gap: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            font-size: 14px;
            margin-top: 4px;
            input {
              margin-top: 5px;
            }
          }
          .form_btn {
            width: 402px;
            height: 56px;
            background: #236eff;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            margin-top: 19px;
            color: white;
            line-height: 56px;
            text-align: center;
          }
        }
      }
    }
  }
  .contact_lazy {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
