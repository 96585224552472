import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/xingyun",
    name: "XingYun",
    component: () => import("../views/XingYun.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About.vue"),
  },
  {
    path: "/information",
    name: "Information",
    component: () => import("../views/Information.vue"),
  },
  {
    path: "/newsinfo",
    name: "NewsInfo",
    component: () => import("../views/NewsInfo.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  scrollBehavior,
  // base: process.env.BASE_URL,
  routes,
});

export default router;
