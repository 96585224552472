<template>
  <div id="app">
    <HeaderNav />
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
    <FooterNav />
    <ContactDialog />
    <ContactFixed />
  </div>
</template>

<script>
import ContactDialog from "@/components/ContactDialog.vue";
import ContactFixed from "@/components/ContactFixed.vue";
export default {
  components: { ContactDialog, ContactFixed },
  data() {
    return {
      index: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  min-width: 1500px;
  font-family: Rubik, Avenir, Helvetica, Arial, sans-serif;
}
</style>
