<template>
  <div class="layout_header">
    <div class="header_nav_bk"></div>
    <div class="header_nav">
      <div class="header_nav_center">
        <img class="log_img" src="../assets/image/logo.png" alt="" />
        <div class="header_nav_link">
          <div
            class="nav_tab"
            v-for="(item, num) in navLink"
            :key="num"
            :class="[route.name == item.name && 'activate']"
            @click="goPage(item.name)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="header_nav_relation">
          <div class="relation_box">
            <div class="relation_box_phone">
              <img src="../assets/icons/phone.png" alt="" srcset="" />
              <div style="margin-left: 4px">{{ config.phone }}</div>
            </div>
            <div class="relation_box_time">
              工作日：{{ config.workingHours }}
            </div>
          </div>
          <div class="header_nav_relation_btn button-ac" @click="openDialog">
            定制方案
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      config: {
        workingHours: "09:00-18:00",
        phone: "027-87003340",
      },

      navLink: [
        {
          title: "首页",
          name: "Home",
        },
        {
          title: "兴云平台",
          name: "XingYun",
        },
        {
          title: "行业资讯",
          name: "Information",
        },
        {
          title: "关于我们",
          name: "About",
        },
      ],
    };
  },
  computed: {
    route() {
      return this.$route;
    },
  },
  methods: {
    goPage(name) {
      this.$router.push({ name });
    },
    openDialog() {
      this.$store.commit("settings/DIALOGSHOW_CHANGE");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout_header {
  .header_nav_bk {
    min-width: 1360px;
    height: 100px;
  }
  .header_nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    min-width: 1360px;
    height: 100px;
    background-color: white;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.15);
    // position: relative;
    z-index: 10;
    .header_nav_center {
      min-width: 1360px;
      max-width: 1500px;
      margin: 0 auto;
      align-items: center;
      display: flex;
      height: 100px;
    }
    .log_img {
      // margin-left: 212px;
      width: 356px;
      height: 39px;
      flex: 0 0 356px;
      cursor: pointer;
      // animation: rollIn 1s;
    }
    .header_nav_link {
      margin-left: 108px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav_tab {
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
      }
      .nav_tab.activate {
        color: #3562ff;
        font-weight: 600;
      }
    }
    .header_nav_relation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-right: 200px;
      margin-left: 144px;
      .relation_box {
        margin-right: 40px;
        .relation_box_phone {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: 800px;
        }
        .relation_box_time {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .header_nav_relation_btn {
        width: 162px;
        height: 52px;
        background: #3562ff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        text-align: center;
        line-height: 52px;
        font-size: 20px;
        font-weight: 800px;
        color: white;
        flex: 0 0 auto;
        cursor: pointer;
      }
    }
  }
}
</style>
