<template>
  <div class="contact_fixed">
    <div
      class="contact_item pointer"
      v-for="(item, index) in contactList"
      :key="index"
      @click="onClickBtn(item)"
    >
      <div>
        <img :src="getImageUrl(item.icon, 'icons')" alt="" />
      </div>
      <div class="contact_title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactList: [
        // {
        //   icon: "contact_wechat",
        //   title: "微信咨询",
        // },
        // {
        //   icon: "contact_message",
        //   title: "立即咨询",
        // },
        {
          icon: "contact_custom",
          title: "申请体验",
        },
        {
          icon: "to_top",
          title: "回到顶部",
        },
      ],
    };
  },
  methods: {
    getImageUrl(icon, path) {
      // 里面可以根据需求写逻辑
      return require(`../assets/${path}/${icon}.png`);
    },
    onClickBtn(data) {
      switch (data.title) {
        case "微信咨询":
          this.$message.error("暂未开放");
          break;
        case "立即咨询":
          this.$message.error("暂未开放");
          break;
        case "申请体验":
          this.$store.commit("settings/DIALOGSHOW_CHANGE");
          break;
        case "回到顶部":
          window.scroll({
            top: 0,
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact_fixed {
  position: fixed;
  right: 40px;
  bottom: 100px;
  z-index: 11;
  .contact_item {
    background-color: #3562ff;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 72px;
    height: 72px;
    img {
      width: 36px;
      // margin-top: 5px;
    }
    .contact_title {
      font-weight: 400;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      // margin-top: 5px;
    }

    &:hover {
      background-color: #214add;
    }
    &:last-child {
      border: none;
    }
  }
}
</style>
