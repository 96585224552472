import request from "@/utils/request";

// banner
export function bannerApi(params) {
  return request({
    url: "/api/v2/member/banner",
    method: "get",
    params,
  });
}

// 一站式解决方案
export function schemeApi() {
  return request({
    url: "/api/v2/member/scheme",
    method: "get",
  });
}

// 合作伙伴
export function partnerApi() {
  return request({
    url: "/api/v2/member/partner",
    method: "get",
  });
}

// 幻灯片
export function slideApi() {
  return request({
    url: "/api/v2/member/slide",
    method: "get",
  });
}

// 新闻列表
export function newsApi(params) {
  return request({
    url: "/api/v2/member/news",
    method: "get",
    params,
  });
}

// 新闻列表
export function tradeApi() {
  return request({
    url: "/api/v2/member/trade",
    method: "get",
  });
}

// 新闻列表
export function guestbook(data) {
  return request({
    url: "/api/v2/member/guestbook",
    method: "post",
    data,
  });
}

// 获取验证码
export function messageApi(data) {
  return request({
    url: "/api/v2/member/message",
    method: "post",
    data,
  });
}

export function newsInfoApi(id) {
  return request({
    url: "/api/v2/member/news/" + id,
    method: "get",
  });
}
