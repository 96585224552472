import { render, staticRenderFns } from "./App.vue?vue&type=template&id=fddc0ed2&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=fddc0ed2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_vue-template-compiler@2.6.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fddc0ed2",
  null
  
)

export default component.exports