<template>
  <div class="footer_nav">
    <div class="footer_nav_center">
      <div class="nav_list">
        <div
          class="nav_list_item pointer"
          v-for="(item, index) in route"
          :key="index"
        >
          <div @click="goPage(item.name)">{{ item.title }}</div>
          <div
            class="nav_list_item_chr"
            v-for="(value, num) in item.chidren"
            :key="num"
            @click="goPage(value.name)"
          >
            {{ value.title }}
          </div>
        </div>
      </div>
      <div class="nav_contact">
        <img class="nav_contact_img" src="../assets/icons/phone_2.png" alt="" />
        <div>
          <div class="nav_contact_phone">027-87003340</div>
          <div class="nav_contact_time">工作日：09:00-18:00</div>
        </div>
      </div>
    </div>
    <div class="footer_copyright">
      <div class="justify-center align-center">
        <a target="_blank" href="https://beian.miit.gov.cn/">
          <p style="margin: 0px 0px 0px 5px">鄂ICP备2023013658号-1</p></a
        >
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <img src="../assets/icons/reference.png" />
        <a target="_blank" href="https://www.beian.gov.cn/">
          <p style="margin: 0px 0px 0px 5px">鄂公网安备 42018502006825号</p></a
        >
      </div>
      <div>Copyright © 武汉兴智数信息科技有限公司 版权所有</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterNav",
  data() {
    return {
      route: [
        {
          title: "首页",
          name: "/",
        },
        {
          title: "兴云平台",
          name: "/xingyun",
        },
        {
          title: "行业资讯",
          name: "/information",
          chidren: [
            {
              title: "兴云动态",
              name: "/information?type=1",
            },
            {
              title: "行业新闻",
              name: "/information?type=2",
            },
          ],
        },
        {
          title: "关于我们",
          name: "/about",
        },
      ],
    };
  },
  methods: {
    goPage(name) {
      this.$router.push({ path: name });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer_nav {
  min-width: 1500px;
  height: 380px;
  background-color: black;
  padding-top: 44px;
  .footer_nav_center {
    width: 1240px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    .nav_list {
      display: flex;
      align-items: flex-start;
      gap: 80px;
      .nav_list_item {
        line-height: 32px;
        font-size: 18px;
        font-weight: 600;
        color: white;
        .nav_list_item_chr {
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
        }
      }
    }
    .nav_contact {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav_contact_img {
        width: 47px;
        margin-right: 5px;
      }
      .nav_contact_phone {
        font-size: 26px;
        font-weight: 600;
        color: white;
      }
      .nav_contact_time {
        font-size: 16px;
        font-weight: 400;
        color: white;
      }
    }
  }
  .footer_copyright {
    width: 1240px;
    margin: 0 auto;
    margin-top: 150px;
    color: #939393;
    padding-top: 10px;
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    a {
      color: #939393;
      text-decoration: none;
    }
    img {
      display: inline-block;
    }
  }
}
</style>
